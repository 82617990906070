import axios from "axios";
import { Message } from "element-ui";
import Router from "../router/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  timeout: 60 * 1000,
});
service.interceptors.request.use(
  (config) => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    config.headers = {
      "Content-Type": "application/json", //配置请求头
    };
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    NProgress.start(); 
    return config;
  },
  (error) => {
    NProgress.done();
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    NProgress.done(); 
    return response.data;
  },
  (error) => {
    NProgress.done();
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        if (error.response.status == 401) {
          Message.error('未授权，请重新登录');
          localStorage.clear();
          sessionStorage.clear();
          Router.push({
            path: "/"
          })
          return
        }
        // 公共错误处理 根据响应码具体处理
        switch (error.response.status) {
            case 400:
                error.message = '错误请求'
                break;
            case 401:
                error.message = '未授权，请重新登录'
                break;
            case 403:
                error.message = '拒绝访问'
                break;
            case 404:
                error.message = '请求错误,未找到该资源'
                break;
            case 405:
                error.message = '请求方法未允许'
                break;
            case 408:
                error.message = '请求超时'
                break;
            case 500:
                error.message = '服务器端出错'
                break;
            case 501:
                error.message = '网络未实现'
                break;
            case 502:
                error.message = '网络错误'
                break;
            case 503:
                error.message = '服务不可用'
                break;
            case 504:
                error.message = '网络超时'
                break;
            case 505:
                error.message = 'http版本不支持该请求'
                break;
            default:
                error.message = `连接错误${error.response.status}`
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            error.message = '服务器响应超时，请刷新当前页';
        } else {
            error.message = '连接服务器失败'
        }
    }
    Message.error(error.message);
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response);
  }
);

export default service;
