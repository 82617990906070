import http from "./http";
let resquest = process.env.VUE_APP_API;

// 新闻列表
export function owJournalism(params) {
  return http.post(`${resquest}/officialWebsite/owJournalism/queryByPage`, params);
}
// 新闻详情
export function journalismQueryById(params) {
  return http.post(`${resquest}/officialWebsite/owJournalism/journalismQueryById/${params.id}`);
}
// 文档列表
export function DocumentList(params) {
  return http.post(`${resquest}/officialWebsite/owInterfaceDocumentBackground/queryByPage`, params);
}
// 文档下载
export function DocumentDownload(params) {
  return http.get(`${resquest}/officialWebsite/owInterfaceDocumentBackground/upload`, params);
}
// 招聘列表
export function owRecruitingList(params) {
  return http.post(`${resquest}/officialWebsite/owRecruiting/queryByPage`, params);
}





